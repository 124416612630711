import { useEffect, useRef } from 'react'
import { useBrowseEnv } from './useBrowseEnv'

export const useBrowseIframe = () => {
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const ROOT_FRAME = useBrowseEnv()

  useEffect(() => {
    function resizeIframe(e: MessageEvent) {
      const this_frame = iframeRef.current

      if (!this_frame) return

      if (this_frame.contentWindow === e.source) {
        this_frame.height = e.data.height + 'px'
        this_frame.style.height = e.data.height + 'px'
      }
    }

    window.addEventListener('message', resizeIframe)
    return () => {
      window.removeEventListener('message', resizeIframe)
    }
  }, [])

  return [iframeRef, ROOT_FRAME] as const
}
