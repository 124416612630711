import { useEffect, useState } from 'react'

export const useBrowseEnv = () => {
  const [baseUrl, setBaseUrl] = useState<string>()

  useEffect(() => {
    let ROOT = 'https://browse.lively.com'
    const hostname = window.location.hostname

    switch (true) {
      case hostname.includes('localhost'):
        ROOT = 'http://localhost:3000'
        break
      case hostname.includes('dev'):
        ROOT = 'https://browse.dev.lively.com'
        break
      case hostname.includes('test'):
        ROOT = 'https://browse.test.lively.com'
        break
      case hostname.includes('staging'):
        ROOT = 'https://browse-magenta.staging.lively.com'
        break
    }

    setBaseUrl(ROOT)
  }, [])

  return baseUrl
}
