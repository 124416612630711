import React from 'react'

import { useBrowseIframe } from '../../hooks/useBrowseIframe'

interface IFCCLabelComponentProps {
  planType: string
  translation?: 'en' | 'es'
}

export const FCCLabelComponent = ({
  planType,
  translation = 'en',
}: IFCCLabelComponentProps) => {
  const [iframeRef, ROOT_FRAME] = useBrowseIframe()
  return (
    <div>
      <h4 className="mb-4 mt-6 md:mt-4">
        {planType === 'Unlimited' && translation === 'es'
          ? 'Ilimitado'
          : planType}
      </h4>

      <div className="max-w-fit rounded-2xl border border-solid border-bby-charcoal-300 shadow-md">
        <div className="flex  max-h-screen justify-center overflow-y-auto overflow-x-hidden ">
          {ROOT_FRAME && (
            <iframe
              src={`${ROOT_FRAME}/${
                translation === 'es' ? 'es/' : ''
              }broadband-labels/${planType}`}
              ref={iframeRef}
              title={`${planType} Broadband Information`}
            ></iframe>
          )}
        </div>
      </div>
    </div>
  )
}
