import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import SEO from '../../components/SEO'
import {
  ContentfulPage,
  ContentfulProduct,
  ContentfulText,
} from '../../../types/graphql-types'
import Layout from '../../components/Layout/Spanish/Layout'
import { FCCLabelComponent } from '../../components/FCCLabel/FCCLabelComponent'
import Text from '../../components/Text'

interface Props {
  data: {
    contentfulPage: ContentfulPage
  }
}

const HeroBackground = styled.section`
  ${tw`max-w-1920 mx-auto md:h-64`}
`
const HeroTextBox = styled.div`
  ${tw`flex content-center flex-wrap h-full`}
  div {
    ${tw`container py-12 text-left text-white`}
    h1 {
      ${tw`leading-none mb-4`}
    }
  }
`

const IntroCopyWrapper = styled.div`
  h2 {
    ${tw`mb-4`}
  }
  p {
    ${tw`lg:mb-24`}
  }
`

export const query = graphql`
  query BroadbandLabelsSpanish {
    contentfulPage(externalName: { eq: "Broadband Labels Spanish" }) {
      title
      externalName
      seoTitle
      seoDescription
      references {
        ... on ContentfulText {
          name
          __typename
          externalName
          id
          richText {
            __typename
            raw
          }
        }
        ... on ContentfulProduct {
          id
          deviceName
          deviceShortName
          deviceCategory
          deviceTypeId
          fccLabel {
            __typename
            planType
          }
        }
      }
    }
  }
`

const BroadbandLabelsSpanishPage: React.FC<Props> = ({ data }: Props) => (
  <Layout>
    <SEO
      title={data.contentfulPage?.seoTitle as string}
      description={data.contentfulPage?.seoDescription as string}
      noIndex={true}
    />
    <HeroBackground className="gradient-3-bby">
      <HeroTextBox>
        <div>
          <h1>{data.contentfulPage?.title}</h1>
        </div>
      </HeroTextBox>
    </HeroBackground>
    <section className="pt-16" key={'smart-fcc-labels'}>
      {data.contentfulPage?.references?.map(content => {
        const text = content as ContentfulText
        const product = content as ContentfulProduct
        // Intro Section
        if (text.externalName === 'Intro') {
          return (
            <div className="container" key={text.id}>
              <IntroCopyWrapper>
                <Text rich={text} />
              </IntroCopyWrapper>
            </div>
          )
        }
        // Jitterbug Smart 4
        if (product?.deviceName === 'Jitterbug Smart4') {
          return (
            <React.Fragment key={'smart-fcc-labels'}>
              <h3 className="container">Planes de datos Jitterbug Smart4 y Kit de Tarjeta SIM</h3>
              <div className="m-4 flex gap-3 overflow-x-auto pt-6 md:gap-8 lg:gap-12 2xl:justify-center">
                {product.fccLabel?.map((label, ind) => (
                  <FCCLabelComponent
                    key={ind}
                    planType={label?.planType as string}
                    translation="es"
                  />
                ))}
              </div>
            </React.Fragment>
          )
        }
        // Jitterbug Flip2
        if (product?.deviceName === 'Jitterbug Flip2') {
          return (
            <div className="container" key={'flip-fcc-labels'}>
              <h3>Jitterbug Flip2</h3>
              <div className="mb-16 grid pt-6 md:grid-cols-3 md:gap-6">
                {product.fccLabel?.map((label, ind) => (
                  <FCCLabelComponent
                    key={ind}
                    planType={label?.planType as string}
                    translation="es"
                  />
                ))}
              </div>
            </div>
          )
        }
      })}
    </section>
  </Layout>
)

export default BroadbandLabelsSpanishPage
